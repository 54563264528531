import { useState } from 'react';
import intro from './milk-and-mocha-cute.gif';
import bears from './bears.gif';
import bearsSad from './bear-sad.gif';
import './App.css';

function App() {
  const [step, changeStep] = useState(1);
  const [crush1, getCrush1] = useState('');
  const [crush2, getCrush2] = useState('');
  const [percentResult, setPercent] = useState('');

  const okNames = [
    'victor', 'viktor', 'madiko', 'madina'
  ];

  const handleNextStep = () => {
    if (crush1 !== '')
      changeStep(2)
  }

  const getResults = () => {
    if (crush2 !== '') {
      changeStep(2)
      if (okNames.includes(crush1.toLowerCase()) && okNames.includes(crush2.toLowerCase())) {
        setPercent('!!!! 100% !!!!!');
      } else {
        setPercent('0%');
      }
      changeStep(3);
    }
  }

  const tryAgain = () => {
    getCrush1('');
    getCrush2('');
    changeStep(1);
    setPercent('');
  }

  return (
    <div className="App">
      <header className="App-header">
        <h1>Viktor and Madiko's Love Calculator</h1>
        <h2>愛の計算機</h2>
        <div class="result">
          <h2>{percentResult}</h2>
          
          <div style={{ maxWidth: '700px' }}>
            <p className={percentResult.indexOf('100%') > -1 ? 'show' : 'hide'} style={{ width: '50%', margin: 'auto' }}>
              You two are perfect together! You should get married and have 1 baby together (hopefully a girl). One of you must be a Leo and the other a Gemini.
              You two will be weird but very happy together. You must go to a couples Onsen together as soon as possible. No relationship has been as perfect as you two! CONGRATULATIONS!
            </p>
          </div>

          <div style={{ maxWidth: '700px' }}>
            <p className={percentResult === '0%' ? 'show' : 'hide'} style={{ width: '50%', margin: 'auto' }}>
              I don't know who you two are but you will never work. If you two were lets say Victor + Madiko then that would be much much much better. But sadly this relationship is doomed and will never work.
              Please try again. Go back to Badoo!
            </p>
          </div>

          <p className={percentResult === '0%' ? 'show' : 'hide'}></p>

          <img src={intro} className={percentResult === '' ? 'show' : 'hide'} alt='intro' />
          <img className={percentResult.indexOf('100%') > -1 ? 'show' : 'hide'} src={bears} alt='bears' style={{ width: '50%', margin: 'auto' }} />
          <img src={bearsSad} className={percentResult === '0%' ? 'show' : 'hide'} alt='intro' style={{ width: '50%', margin: 'auto'}} />
        </div>

        <div className={step === 1 ? 'show' : 'hide'}>
          <p>
            Enter your name
          </p>
          <br/>
          <input id="step1Name" onChange={ e => getCrush1(e.target.value) } value={crush1} />
          <br/>
          <br/>
          <button class="button" type='button' onClick={handleNextStep}>Next</button>
        </div>

        <div className={step === 2 ? 'show' : 'hide'}>
          <p>
            Enter your crush's name
          </p>
          <br/>
          <input id="step2Name" onChange={ e => getCrush2(e.target.value) } value={crush2}/>
          <br/>
          <br/>
          <button href="" class="button" type='button' onClick={getResults}>Get Your Results</button>
        </div>

        <div className={step === 3 ? 'show' : 'hide'}>
          <button href="" class="button" type='button' onClick={tryAgain}>Try Again?</button>
        </div>
      </header>
    </div>
  );
}

export default App;
